<template>
  <div style="font-family: pingfang">
    <!-- <combo-head ref="upChild" :search="upHead"/> -->
    <div>
      <van-row class="amount">
        <van-col span="12" class="amount-first">
          <!-- <span style="white-space: nowrap; margin-left: -12px;"
            >{{ operator }}</span><br /> -->
            <div style="height: 8px;"></div>
          <div style="white-space: nowrap;font-size: 0.9rem;margin-left: -12px;">卡 号:<span style="margin-left:8px;">{{ msisdn }}</span></div>
            <div style="height: 8px;"></div>
          <div style="white-space: nowrap;font-size: 0.9rem;margin-left: -12px;">iccid:<span style="margin-left:10px;">{{iccId}}</span></div>
          <!-- <span style="font-size: 0.7rem; color: #b2b2b2">首充享优惠</span><br /> -->
          <!-- <span @click="goToCobmo">{{ goTitle }}&nbsp;></span> -->
        </van-col>
        <van-col span="12" class="amount-two">
          <span v-if="comboType == 1">余额(元)</span><br /><br />
          <span v-if="comboType == 1" style="font-size: 2rem">{{ balance }}</span>
        </van-col>
      </van-row>
      <van-row class="detail">
        <van-col span="8" style="padding-right: 3rem">
          <span>{{ nowMonth }}月</span><br />
          <span>账单</span>
        </van-col>
        <van-col span="8" @click="toUrl('voiceAdd')">
          <span>{{ residueVoice }}</span
          ><br />
          <span>剩余语音</span>
        </van-col>
        <van-col span="8" style="padding-left: 3rem" @click="toUrl('flowAdd')">
          <span>{{ residueFlow }}</span
          ><br />
          <span>剩余流量</span>
        </van-col>
      </van-row>
  </div>
    <div class="navFather">
      <div v-if="comboType == 0" @click="toNav(0)" :style="{color: navFlag == 0 ? '#3185ff':''}">
        <!-- <div style="font-size: 40px;float: left;" v-show="navFlag == 0">・</div> -->
        基本套餐
      </div>
      <div v-if="comboType == 0" @click="toNav(1)" :style="{color: navFlag == 1 ? '#3185ff':''}">
        <!-- <div style="font-size: 40px;float: left;" v-show="navFlag == 1">・</div> -->
        增值包
      </div>

      <div v-if="comboType == 1" @click="toNav(2)" :style="{color: navFlag == 2 ? '#3185ff':''}">
        <!-- <div style="font-size: 40px;float: left;" v-show="navFlag == 1">・</div> -->
        充值余额
      </div>
      <div v-if="comboType == 1" @click="toNav(1)" :style="{color: navFlag == 1 ? '#3185ff':''}">
        <!-- <div style="font-size: 40px;float: left;" v-show="navFlag == 1">・</div> -->
        增值包
      </div>
    </div>
    
    <van-tabs v-if="comboType == 0" tab-class="tabs" :swipe-threshold="6" v-model="active" style="margin:5px" @click-tab="upTab" v-on:click-tab="upTab"  v-on:click="upTab"  sticky v-show="navFlag == 0"> <!-- swipeable -->
      <van-tab :title="off.lable" v-for="(off,ixx) in navTabs" :key="off.key"><!-- @touchstart="touchStart" @touchend="touchEnd" -->
              
              <!-- 套餐 -->
              <span v-show="isNone" style="margin-left:20px;">暂无套餐</span>
              <div
                v-for="(item, ik) in comboList"
                :key="ik"
                class="content"
                @click="comboChoose(item)"
                
              >
                <van-row :class="comboActive == item.comboId ? 'active' : ''">
                  <van-col span="18" style="padding: 10px">
                    <span class="custom-title">{{ item.comboTitle }}</span
                    ><br />
                    <!-- <van-tag
                      :type="item.indexOf('流量')!=(-1)?tags[0]:item.indexOf('通话')!=(-1)?tags[1]:item.indexOf('短信')!=(-1)?tags[2]:tags[3]" -->
                    <van-tag
                      :type="getTagType(tag,key)"
                      style="margin: 3px"
                      v-for="(tag, key) in item.tags"
                      :key="key"
                    >
                      {{ tag }}
                    </van-tag>
                  </van-col>
                  <van-col span="6" class="right-content">
                    <div>
                      <span>￥{{ item.currentPrice }}</span>
                    </div>
                  </van-col>
                </van-row>
              </div>
              <!-- 套餐包 -->
              <div
                v-for="(item, ik) in comboPackage"
                :key="ik"
                class="content"
                @click="packChoose(item)"
              >
                <van-row :class="comboActive == item.cpId ? 'active' : ''">
                  <van-col span="18" style="padding: 10px">
                    <span class="custom-title">{{ item.title }}</span
                    ><br />
                    <van-tag
                      :type="tags[key > 2? key :3]"
                      style="margin: 3px"
                      v-for="(tag, key) in item.tags"
                      :key="key"
                    >
                      {{ tag }}
                    </van-tag>
                  </van-col>
                  <van-col span="6" class="right-content">
                    <div>
                      <span>￥{{ item.currentPrice }}</span>
                    </div>
                  </van-col>
                </van-row>
              </div>
              <!-- <div style="padding: 10px" v-show="this.addCombo.length > 0">增值包</div> -->
      </van-tab>
    </van-tabs>
    
              <!-- 增值包 -->
              <div
                v-for="(item, ik) in addCombo"
                :key="ik"
                class="content"
                @click="addComboChoose(item)"
                v-show="navFlag == 1"
              >
                <van-row :class="comboActive == item.comboId ? 'active' : ''">
                  <van-col span="18" style="padding: 10px">
                    <span class="custom-title">{{ item.comboTitle }}</span
                    ><br />
                    <van-tag
                      :type="getTagType(tag,key)"
                      style="margin: 3px"
                      v-for="(tag, key) in item.tags"
                      :key="key"
                    >
                      {{ tag }}
                    </van-tag>
                  </van-col>
                  <van-col span="6" class="right-content">
                    <div>
                      <span>￥{{ item.currentPrice }}</span>
                    </div>
                  </van-col>
                </van-row>
                <div v-show="addCombo.length==0" style="margin-left:20px;">暂无增值包</div>
              </div>
              
              <!-- 余额包 -->
              <div
                v-for="(item, ik) in balanceList"
                :key="ik"
                class="content"
                @click="abalanceChoose(item)"
                v-show="navFlag == 2"
              >
                <van-row :class="comboActive == item.ccbId ? 'active' : ''">
                  <van-col span="18" style="padding: 10px">
                    <span class="custom-title">{{ item.ccbName }}</span
                    ><br />
                      <van-tag type="danger" style="margin: 3px"
                        >到账{{ item.arrivalAmount }}</van-tag
                      >
                  </van-col>
                  <van-col span="6" class="right-content">
                    <div>
                      <span>￥{{ item.currentPrice }}</span>
                    </div>
                  </van-col>
                </van-row>
                <div v-show="balanceList.length==0" style="margin-left:20px;">暂无增值包</div>
              </div>
              

              <!-- 试用包 -->
          <!-- <div style="margin-top: ;50px">
                
              <div
                v-for="(item, ik) in comboList[1]"
                :key="ik"
                class="content"
                @click="comboChoose(item)"
                
              >
                <van-row :class="comboActive == item.comboId ? 'active' : ''">
                  <van-col span="18" style="padding: 10px">
                    <span class="custom-title">{{ item.comboTitle }}</span
                    ><br />
                    <van-tag
                      :type="getTagType(tag,key)"
                      style="margin: 3px"
                      v-for="(tag, key) in item.tags"
                      :key="key"
                    >
                      {{ tag }}
                    </van-tag>
                  </van-col>
                  <van-col span="6" class="right-content">
                    <div>
                      <span>￥{{ item.currentPrice }}</span>
                    </div>
                  </van-col>
                </van-row>
              </div>
          </div> -->



    <div class="tips">
      <h4>温馨提示</h4>
      <p style="font-size: 10px;">1.本卡只在本公众号充值有效</p>
      <p style="font-size: 10px;">2.物联卡需充值和实名后方可使用</p>
      <p style="font-size: 10px;">3.网络延迟导致异常情况请尝试重新进入</p>
      <p style="font-size: 10px;">4.如遇问题请联系公众号客服处理</p>
    </div>
    <div style="height: 40px" />
    <common-footer />
    <!--弹出层，确认是否购买以及介绍套餐详情-->
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '70%' }"
    >
    <div style="position:sticky;top:0;background-color: white;z-index: 99;">
      <div style="height:10px;"></div>
      <van-card
        :tag="itemTag"
        :price="itemPrice"
        :desc="itemDesc"
        thumb="/images/fg.png"
        :title="itemTitle"
        :origin-price="itemOriginPrice"
         style="margin-top:0px;"
      />
        <!-- :thumb="this.$store.getters.corp == 1?'/images/zgyd.png':this.$store.getters.corp == 2?'/images/zglt.png':'/images/zgdx.png'" -->
        <!-- style="position:sticky;top:0;z-index: 99;padding-top: 10px;" -->
    </div>
    <div v-show="this.navFlag != 2">
      <van-divider>套餐描述</van-divider>
      <div style="color:#969799;font-size:14px;margin-left:5px;">购买后即刻生效</div>
      <div v-if="navFlag != 1" style="color:#969799;font-size:14px;margin-left:5px;">如有套餐的情况无法继续充值，如超量请充值增值包</div>
      <div class="tips" style="white-space: pre-wrap;">
      <!-- <div></div>
        <p class="tipsItem">
          <span style="font-size: 0.5rem;margin-right:5px;">●</span> 
          <span>购买后即刻生效</span> </p>
        <p>{{ itemDesc }}</p> -->
        <p v-for="(tag, key) in this.goods.tags" :key="key" class="tipsItem">
          <span style="font-size: 0.5rem;margin-right:5px;">●</span> 
          <span>{{ tag }}</span> </p>
        <p>{{ itemDesc }}</p>
      </div>
    </div>
      <van-radio-group v-model="payType" style="height: 300px;">
        <van-cell-group>
          <van-cell
            title="微信支付"
            icon="chat-o"
            clickable
            @click="choosePayType(1)"
          >
            <template #right-icon>
              <van-radio :name="1" icon="passed" />
            </template>
          </van-cell>
          <van-cell
            v-if="comboType == 1"
            title="余额支付"
            icon="paid"
            clickable
            @click="choosePayType(3)"
            v-show="navFlag != 2"
          >
            <template #right-icon>
              <van-radio :disabled ="balance < itemPrice" :name="3"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <!-- <van-field
          v-model="packTime"
          readonly
          clickable
          name="datetimePicker"
          label="套餐生效日期"
          placeholder="点击选择时间"
          input-align="right"
          @click="showPicker = true"
          />
          <van-popup v-model:show="showPicker" position="bottom">
          <van-datetime-picker
            title="选择年月"
            type="year-month"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->
      <van-submit-bar
        :price="payPrice"
        button-text="确认支付"
        :disabled="(isHaveCombo==1)&&navFlag != 1"
        tip='请仔细确认套餐信息'
        tip-icon="info-o"
        :loading="loading"
        decimal-length="2"
        @submit="buyCombo"
      />
    </van-popup>
  </div>
</template>
<script>
import { getCombos, createOrder, selectCardUsed, removeOrder} from "@/api/card";
// import { testUtil } from "@/api/util";
import wechatUtil from "@/utils/wechatUtil";
import { Dialog,Notify,Toast } from "vant";
import "@/assets/combo.css";
import comboHead from "@/views/combos/header.vue";
import commonFooter from "@/views/common/footer.vue";
import { parseTime } from "@/utils/index";
import { calculateFlow } from "@/utils/convert";
import {  getUrlParam } from "@/api/user";
import store from "@/store";
import Cookies from "js-cookie";
// import { selectCardUsed } from "@/api/card";
// import { Dialog,Notify } from "vant";

export default {
  name: "User",
  components: {
    comboHead,
    commonFooter,
  },
  data() {
    return {
      goTitle: "充值余额",
      goName: "balance",
      msisdn: "",
      iccId: "",
      balance: 0,
      balanceList: [],
      residueFlow: "0M",
      residueVoice: "0分",
      nowMonth: parseTime(new Date(), "{m}"),
      operator: "中国移动",
      expiryDate: "",

      loading:false,
      checked: true,
      comboList: [],
      comboPackage: [],
      addCombo: [],
      comboActive: 0,
      goods: null,
      payPrice: 0,
      itemPrice: 0,
      itemOriginPrice: 0,
      itemTitle: "商品标题",
      itemDesc: "描述信息",
      show: false,
      payType: 1,
      itemTag: "推荐",
      showPicker: false,
      packTime: null,
      tags: [
        "primary",
        "success",
        "danger",
        "warning"
      ],
      // // navMenu导航高亮
      // activeIndex: "1",
      // // 运营商
      // oper:[
      //   {name:'中国移动',value:'1'},
      //   {name:'中国联通',value:'2'},
      //   {name:'中国电信',value:'3'},
      // ],
      // activeSty: 1,
      active:3,
      // 控制nav内容的flag
      tagFalg:0,
      j:0,
      k:0,
      comboTabs:[
        {lable:'试用包',value:2,key:0},
        {lable:'月套餐',value:30,key:1},
        {lable:'季套餐',value:90,key:2},
        {lable:'半年包',value:180,key:3},
        {lable:'一年包',value:360,key:4},
        {lable:'两年包',value:720,key:5},
        // {lable:'增值包',value:6},
      ],
      // orderTypes:[
      //   {lable:'微信',value:1,key:0},
      //   {lable:'支付宝',value:2,key:1},
      //   {lable:'余额',value:3,key:2},
      //   {lable:'其他方式',value:4,key:3},
      //   {lable:'后台',value:5,key:4},
      // ],
      // addTabs:[
        // {lable:'',value:-1}
      // ],
      navTabs:[],
      navOne:0,
      navFlag:-1,
      numDays:2,
      // 刷新流量显示
      upHead:true,
      //初始化点击位置的x坐标
      startX:0,
      // 当前是否有套餐
      isHaveCombo:null,
      // 套餐流量是否已超出
      isOverCombo:null,
      isToDelTry:false,
      isNone:false,//暂无套餐文字 默认不显示,
      comboType:0,
    };
  },
  watch:{
    'show':'downShow'
  },
  mounted(){
    
    const routerPath = this.$route.name;
    if (routerPath == "balance") {
      this.goTitle = "充值套餐";
      this.goName = "combo";
    }
  },
  created() {    
    sessionStorage.setItem('loginRef',0)//防止登录页面刷新导致code失效 01api错误 还原
    // localStorage.setItem('SET_IS_REAL_NAME',0)
    this.getCardInfo();
    // this.getCardUsed();
    
    let nn = 60
    let update = 'testNum'+nn
    localStorage.setItem(update,0)//------------
    
  },
  methods: {
    // 选择支付方式时触发
    choosePayType(payId){
      // 微信
      if(payId == 1){
        this.payType = payId
      }
      // 余额
      else if(payId == 3){
        if(this.balance < this.itemPrice){
          this.payType = 1
          Toast({
                  message: "余额不足",
                  position: "bottom",
                });
        }else{
          this.payType = payId
        }
      }
      
    },
    toRealName(payRealName){
      
          // if(localStorage.getItem('admin') == 1 && localStorage.getItem('realNameFlag') == 1){
            if(localStorage.getItem('SET_REAL_NAME_AUTH') == 0 && (localStorage.getItem('realNameFlag') == 1)){// || payRealName == 1)
              
              const beforeClose = (action) =>
              new Promise((resolve) => {
                // this.copyToClip()
                // Toast({
                //   message: "iccid已复制",
                //   position: "bottom",
                // });
                setTimeout(() => {
                  if (action === 'confirm') {
                    resolve(true);
                  } else {
                    // 拦截取消操作
                    // resolve(false);
                  }
                },1500);
              });
              Dialog.alert({
                title: '提示',
                message: '您还未实名，请实名后使用!',
                beforeClose,
                theme: 'round-button',
              }).then(() => {
                localStorage.setItem('realNameFlag',0)
                let corp = Cookies.get('corp')
                
                if (corp == 1) {
                  this.toUrl('info',true)//跳转实名
                } else if (corp == 2) {
                  this.toUrl('info',true)//跳转实名
                } else if (corp == 4) {
                  window.open(
                    // "http://uniteapp.ctwing.cn:9090/uapp/certifhtml/certif_entry.html?userCode=nGmFFq6z4WZxGKIgPw+jBw==&passWord=97xjIqkYSeyG6wc5YiqGjA=="
                    "https://cmp-miniapps.ctwing.cn/#/pages/ordinaryuserRealName/index"
                  );
                }
              }).catch(()=>{
                return;
              });
            }
    },
    // isTry(){
    //   // 如果试用套餐已不可购买，直接不显示当前栏
    //   // 套餐周期类型赋值
    //       let isC = localStorage.getItem('isHaveCombo')
    //       let res = JSON.parse(localStorage.getItem('thisCombo'))
    //       let f = res.isTry == 0 && isC == 0
    //       console.log('istry',f)
    //       return f
    // },
    // 订单弹窗关闭时，按钮加载一起停止
    downShow(newer,older){
      if(!newer){
        this.loading = false
      }
    },
    // getPayType(val){
    //   console.log('支付方式',val)
    //   this.payType = val
    // },
    toUrl(rout,realName){
      let wid = '/'+localStorage.getItem('resFlog1')
      // console.log('wid',wid)
      this.$router.push("/"+rout+wid)
      if(realName){
        localStorage.setItem('testKey','这是一个测试的value值'); // 存入一个值
        store.commit("SET_IS_REAL_NAME", true);
        // localStorage.setItem('SET_IS_REAL_NAME', true)
        let aaa = localStorage.getItem('testKey')
        // console.log('本地',aaa)
      }
    },
    getCardInfo() {
      // console.log('===========================info')  
      let wid = localStorage.getItem('resFlog1') 
      let user = localStorage.getItem('user-info')
      let iccid = Cookies.get('card-id');//dispatch("getCardInfo"setCard-id,没有set就get
      this.$store
        .dispatch("getCardInfo", {
          iccid:iccid || user.iccid,
          officialId: wid,
        })
        .then((res) => {
          // console.log('卡信息',res);
          localStorage.setItem('thisCombo',JSON.stringify(res))
          // 防止在个人页面刷新，卡数据在store会被重置，白名单列表会被显示出来
          localStorage.setItem('SET_REAL_NAME_AUTH', res.realNameAuth)
          
          this.toRealName()


          localStorage.setItem('SET_CARD_TYPE', res.type)
          this.msisdn = res.msisdn;
          this.iccId = res.iccid;
          this.balance = res.balance;
          this.operator =
            res.corp === 1
              ? "中国移动"
              : res.corp === 2
              ? "中国联通"
              : "中国电信";

          this.getCardUsed()
        });
    },
    // goToCobmo() {
    //   this.$router.push({
    //     name: this.goName,
    //     params: { officialId: this.$store.getters.officialId },
    //   });
    // },
    getCardUsed() {
      // console.log('===========================used')
      let iccid = Cookies.get('card-id');
      selectCardUsed(
        iccid,
        // parseTime(new Date(), "{y}{m}")
      ).then((res) => {
        console.log("用户卡流量信息",res);
        this.isHaveCombo = res? 1 : 0
        this.isOverCombo = res?(res.totalFlow<=res.usedFlow? 1 : 0):0
        localStorage.setItem('isHaveCombo',this.isHaveCombo)
        localStorage.setItem('isOverCombo',this.isOverCombo)
        // console.log('当前是否有套餐',this.isHaveCombo)
        if (res) {
          let cardUsed = calculateFlow(res)||0;
          this.residueFlow = cardUsed.residueFlowUnit;
          this.residueVoice = cardUsed.residueVoiceUnit;
          this.nowMonth = (new Date()).getMonth()+1;
          this.expiryDate = cardUsed.expiryDate;
        }
        
          this.$nextTick(()=>{
            // if(localStorage.getItem('admin') == 1){
            // 如果试用套餐已不可购买，直接不显示当前栏
            // 套餐周期类型赋值
            let isC = localStorage.getItem('isHaveCombo')
            let res = JSON.parse(localStorage.getItem('thisCombo'))
            // console.log(res.isTry,isC)

                // 如果没买过体验包、当前没有套餐
                if(res.isTry == 0 && isC == 0 ){
                  // console.log('00000000')
                }else{
                  this.comboTabs.forEach((n)=>{
                    if(n.value == 2){
                      this.comboTabs.splice(0,1)
                      this.numDays = 30
                    }
                  })
                }
            // }
            this.navTabs = this.comboTabs
            this.getComboList();
          })
      });
    },


    /**
    * 触摸开始
    * @param {Object} e
    */
    touchStart(e) {
      if (e.touches.length == 1) {
        //设置触摸起始点水平方向位置
        this.startX = e.touches[0].clientX;
      }
    },
    /**
     * 触摸结束
     * @param {Object} e
     */
    touchEnd(e) {
      if (e.changedTouches.length == 1) {
        //手指移动结束后水平位置
        var endX = e.changedTouches[0].clientX;
        let diff = endX - this.startX;
        if (Math.abs(diff) > 20) {
          if (diff > 0) {
            let b =0
            this.comboTabs.forEach((n,i)=>{
              if(this.numDays == n.value && this.numDays != 720){
                // this.numDays = this.comboTabs[i-1].value
                b =  this.comboTabs[i-1].value
                // console.log("左...",this.numDays);
              }
            })
            this.numDays = b
            this.comboList=[];
            this.getComboList()
          } else {
            let b =0
            this.comboTabs.forEach((n,i)=>{
              if(this.numDays == n.value && this.numDays != 720){
                // this.numDays = this.comboTabs[i+1].value
                b =  this.comboTabs[i+1].value
                console.log("右...",this.numDays);
              }
            })
            this.numDays = b
            this.comboList=[];
            this.getComboList()
          }
        }
      }

    },

    toNav(navId){
        // 套餐类型nav不能原地跳转，造成基本套餐出现numDays bug
        if(this.navFlag != navId){
          // 每次跳转初始化基本套餐列表
            this.comboList=[];
            this.numDays = this.comboTabs[0].value
            if(navId == 0){this.getComboList()}
            this.navTabs = navId == 0 ?this.comboTabs : ''
            this.navFlag = navId
        }
    },
    getTagType(val){
      // let type = key > 2? key :3
      let type = 3
      // console.log(val,typeof(val))
      let str = val
      // console.log(str,typeof(str))
      if(str.indexOf('流量') != -1){
        type = 0
      }
      else if(str.indexOf('通话') != -1){
        type = 1
      }
      else if(str.indexOf('短信') != -1){
        type = 2
      }
      // }
      // console.log(type)
      return this.tags[type]
    },
    upTab(e){
      // console.log(e)
      this.comboTabs.forEach(n=>{
        // 这里因为tab组件传的val值是固定的下标，所以因为试用包的移除，需要向下比较
          let isC = localStorage.getItem('isHaveCombo')
          let res = JSON.parse(localStorage.getItem('thisCombo'))
          // console.log(res.isTry,isC)
          // 如果没买过体验包、当前没有套餐
          if(res.isTry == 0 && isC == 0){
            if(n.key == e){
              this.numDays = n.value
            }
          }else{
            if(n.key-1 == e){
              this.numDays = n.value
            }
        }
      })
      this.comboList=[];
      this.$nextTick(()=>{
        this.getComboList()
      })
    },
    // handleSelect(key) {
    //   console.log(key);
    //   this.activeSty = key
    // },
    onConfirm(time) {
      this.packTime = this.formatDate(time);
      this.showPicker = true;
    },
    formatDate(inputTime) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      return y + "-" + m;
    },
    // 获取套餐列表
    getComboList() {
      getCombos(this.numDays).then(
        (res) => {
          if(this.navOne == 0){
            this.navFlag = res.groupType ==1?2:0
          }
          this.navOne = 1
          this.comboType = res.groupType
          localStorage.setItem('comboType',this.comboType)
          // 正常套餐
          if(this.comboType == 0){
            let thisCombo = JSON.parse(localStorage.getItem('thisCombo'))
            this.getTagList(res.groupList[0]).forEach((n,i)=>{
            // this.getTagList(res[0]).forEach((n,i)=>{
                  if(n.comboValidDateValue == this.numDays){
                    if(thisCombo.isTry == 1 &&  this.numDays == 2){

                    }else{
                      this.comboList.push(n);
                    }
                  }
              
            })
          }
          // 余额套餐
          else if(this.comboType == 1){
            this.balanceList = this.getTagList(res.groupList[2]);
          }
          // this.comboPackage = this.getTagList(res[1]);//套餐包 以去除
          // this.balanceList = this.getTagList(res[2]);
          this.addCombo = this.getTagList(res.groupList[3]);
      });
    },
    getTagList(combos) {
      for (let combo of combos) {
        if (combo.extendStr) {
            combo.tags = combo.extendStr.split(",");
        }
      }
      return combos;
    },
    comboChoose(item) {
      // 防止已在当前但还是点击
      // if(this.navFlag != 0){
        // console.log(item)
        if(this.isHaveCombo){
          if(this.isOverCombo == 1){
            Notify({ type: 'warning', message: '当前套餐流量已用尽,可购买增值包充值流量!' });
          }else{
            Notify({ type: 'warning', message: '已有套餐正在使用,可购买增值包充值流量!' });
          }
        }
        this.comboActive = item.comboId;
        this.itemTitle = item.comboTitle;

        this.payType = 1;
        this.goods = item;
        this.payPrice = item.currentPrice * 100;
        this.itemPrice = item.currentPrice;
        this.itemOriginPrice = item.originalPrice;
        this.itemDesc = item.remark == "null" ? "" : item.remark;
        this.show = true;
        // 试用包未购买就标记，若下单但未购买就删除订单
        // this.isToDelTry = item.comboValidDateValue == 2 ? true:false
      // }
    },
    packChoose(item) {
      // 防止已在当前但还是点击
      // if(this.navFlag != 0){
        this.comboActive = item.cpId;
        this.itemTitle = item.title;

        this.goods = item;
        this.goods.comboId = item.cpId;
        this.payPrice = item.currentPrice * 100;
        this.itemPrice = item.currentPrice;
        this.itemOriginPrice = item.originalPrice;
        this.itemDesc = item.remark == "null" ? "" : item.remark;
        this.show = true;
      // }
    },
    addComboChoose(item) {
      // 防止已在当前但还是点击
      // if(this.navFlag != 1){
        // console.log(item)
        this.comboActive = item.comboId;
        this.itemTitle = item.comboTitle;

        this.goods = item;
        this.payPrice = item.currentPrice * 100;
        this.itemPrice = item.currentPrice;
        this.itemOriginPrice = item.originalPrice;
        this.itemDesc = item.remark == "null" ? "" : item.remark;
        this.show = true;
      // }
    },
    abalanceChoose(item) {
      // 防止已在当前但还是点击
      // if(this.navFlag != 2){
        // console.log(item)
        this.comboActive = item.ccbId;
        this.itemTitle = item.ccbName;

        this.goods = item;
        this.payPrice = item.currentPrice * 100;
        this.itemPrice = item.currentPrice;
        this.itemOriginPrice = item.originalPrice;
        this.itemDesc = item.title ||'';
        this.show = true;
      // }
    },
    buyCombo() {
      // 购买时刷新页面流量信息
      // this.$refs.upChild.getCardInfo()
      // this.$refs.upChild.getCardUsed()
      this.loading = true;
      // console.log('getter的iccid',this.$store.getters.iccId)
      let user = JSON.parse(localStorage.getItem('user-info'))
      let locAppId = localStorage.getItem('resFlog1')
      let cardIccid = Cookies.get('card-id')
      createOrder(
        cardIccid||user.iccid,
        this.goods.groupId,
        this.navFlag != 2?this.goods.comboId:this.goods.ccbId,
        this.payType,
        locAppId||user.officialId,
        user.openid
      ).then((res) => {
          if (this.payType == "1") {
            wechatUtil.init(["chooseWXPay"]).then((wx) => {
              this.loading = false;
              // console.log(wx, "wx");
              // Notify({ type: 'success', message: res.appid});
              wx.chooseWXPay({
                appId: locAppId,//本地链接的公众号id
                // appId: user.officialId,//本地用户数据的公众号id
                // appId: res.appId,//返回值所带的appid
                timestamp: res.timestamp,
                nonceStr: res.nonce_str,
                package: res.prepay_id,
                signType: res.signType,
                paySign: res.sign,
                success: (e)=> {
                  // this.isToDelTry = false
                  // console.log(e)
                  this.comboList = []
                  this.getCardInfo();
                  this.show = false;

                  // 成功通知
                  Notify({ type: 'success', message: '支付成功'});
                  // if(this.$store.getters.realNameAuth==0){
                  // this.toRealName(1)

                },
                error: () => {
                  this.comboList = []
                  this.getCardInfo();
                  // this.getCardUsed();//包含// this.getComboList()
                  this.show = false;
                  // 危险通知
                  Notify({ type: 'danger', message: '支付出错' });
                },
                // 接口调用完成时执行的回调函数，无论成功或失败都会执行
                complete: (e)=>{
                  
                }
                
              });
            }).catch(()=>{
              this.loading = false;
            });
          }else if(this.payType == "3"){
            setTimeout(()=>{
              this.loading = false;
              // 余额支付
              this.show = false;
              this.comboList = []
              this.getCardInfo();
              // this.getCardUsed();//包含// this.getComboList()
              // 成功通知
              Notify({ type: 'success', message: '支付成功' });
            },1000)
          }
      }).catch(()=>{
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.navFather{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}
/* .navFather div{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
} */
.tabs{
  flex: none !important;
}
.content{
      -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @-webkit-keyframes scale-in-center {
      0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          opacity: 1;
      }
      100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          opacity: 1;
      }
  }
  @keyframes scale-in-center {
      0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          opacity: 1;
      }
      100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          opacity: 1;
      }
  }

</style>
